<template>
    <div>
        <!-- Rating stars -->
        <form @submit.prevent="rateWriter" style="max-width: 476px">
            <div class="">
                <h4>Rate & Review this Writer</h4>
                <p class="is-rating-stars-1 mb-3 d-flex" style="margin-left:-8px">
                    <button type="button" v-for="(i, index) in 5" :key="index + 'rate'"  class="btn btn-lg bg-warning btn-icon  border-warning border-2 rounded-round mx-1" :class="rateForm.rating >= index + 1 ? 'text-light' : 'btn-outline text-warning'"  @click="rateForm.rating = index + 1">
                        <i class="icon-star-full2" ></i>
                        <!-- {{i+1}} -->
                    </button>
                    <span class="btn btn-outline btn-lg bg-light btn-icon text-dark border-dark border-2 rounded-round mx-1 px-2"> {{ rateForm.rating || "" }} Stars</span>
                </p>
                <div class="is-input-section ">
                    <label>Describe your experience</label> 
                    <textarea required class="form-control border-grey" v-model="rateForm.message" name="message" rows="3" placeholder="Review goes here" ></textarea>
                </div>
                <button type="submit" class="btn btn-success my-2" :disabled="rateForm.busy">Submit Review</button>
            </div>
        </form>
        <!-- review -->
        <!-- post -->
        <!-- reviews -->
        <BaseOverlay v-if="is_loading"></BaseOverlay>

    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    computed: {
        ...mapState("order", ["order"])
    },
    data () {
        return {
            is_loading: false,
            rateForm: new this.$Form({
                rating: 0,
                message: ""
            }),
        }
    },

    mounted() {
        if (this.order.writer.rating.rate) this.rateForm.rating = this.order.writer.rating.rate
    },

    methods: {
        ...mapActions("order", ["_getOrder"]),
        rateWriter() {
            if (this.rateForm.rating >= 1) {
                console.log(this.rateForm);
                this.is_loading = true;
                this.rateForm
                    .post(`${process.env.VUE_APP_API_BASE_URL}/orders/Rate/${this.order.id}`)
                    .then(() => {
                        this.$notify({
                            title: "Success",
                            text: "Writer rated successifully!",
                        });
                        
                    })
                    .then(() => this._getOrder(this.order.id))
                    .catch((err) => {
                        this.$notify({
                            title: "Error",
                            text: err.response.data ? err.response.data.Message : "Error",
                            style: "danger",
                        });
                    })
                    .finally(() => this.is_loading = false);
            }  else this.$notify({
                    title: "Error",
                    text: "Rating stars must not be '0'",
                    style: "danger",
                });

        }
    }


}
</script>

<style lang="scss" >
    .is-rating-stars-1{
        span.icon{
            font-size:34px;
        }
    }
</style>