<template>
  <div class="text-left">
    <!-- <h1 class="my-2">
      Order Files
    </h1> -->
    <div class="">
      <div class="p-1">
        <div class="row">
          <div class="col-12 col-md-6">
            <!-- our uploads  -->
            <h4 class="my-0">Files We've Uploaded</h4>
            <p>
              Note: These are files uploaded by our team. I.e. Writer, editor or
              support.
            </p>
            <div
              class="bg-light border-0 rounded p-0 mb-2"
              v-if="
                order.files.our_uploads && order.files.our_uploads.length > 0
              "
            >
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Filename</th>
                      <th>Uploaded At</th>
                      <th>Uploaded By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tr
                    v-for="(file, index) in order.files.our_uploads"
                    :key="index + 'ofiles'"
                  >
                    <td>
                      <a
                        style="cursor: pointer; word-break: break-all"
                        class="text-primary"
                        @click="downloadFile(file)"
                        >{{ file.file_name || "" }}</a
                      >
                    </td>
                    <td>{{ file.date || "" }}</td>
                    <td>{{ file.type || "" }}</td>
                    <td>
                      <span v-if="is_downloading && activeFile.id == file.id"
                        ><img
                          width="30px"
                          :src="loading_gif"
                          alt="Loading..."
                        />
                      </span>
                      <button
                        v-else
                        class="btn btn-outline-success btn-sm"
                        @click="downloadFile(file)"
                      >
                        <span class="icon-download mr-0 mr-sm-1"></span>
                        <span class="d-none d-sm-inline">Download</span>
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <p v-else class="p-2 bg-light border-0 rounded">
              No order files. <br />
              Files that we have uploaded will appear here.
            </p>

            <!-- our uploads -->
          </div>
          <div class="col-12 col-md-6">
            <!-- our uploads  -->
            <h4 class="my-0">Upload Files</h4>
            <div class="row">
              <div class="col-12 col-sm-7 col-md-9">
                <FileUploader v-model="orderForm.files"></FileUploader>
              </div>
              <div class="col-12 col-sm-4 col-md-3 pl-2 pl-sm-0">
                <button
                  class="btn btn-primary btn-block"
                  style="padding-top: 8px; padding-bottom: 8px; margin-top: 2px"
                  @click="uploadFiles"
                  :disabled="is_uploading"
                >
                  {{ is_uploading ? "Uploading" : "Upload" }}
                </button>
              </div>
            </div>
            <h4 class="my-0">
              Your Uploads
            </h4>

            <div
              class="bg-light border-0 rounded p-0 mb-2"
              v-if="
                order.files.client_uploads &&
                order.files.client_uploads.length > 0
              "
            >
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Filename</th>
                      <th>Uploaded At</th>
                      <th>Uploaded By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tr
                    v-for="(file, index) in order.files.client_uploads"
                    :key="index + 'ofiles'"
                  >
                    <td>
                      <a
                        style="cursor: pointer; word-break: break-all"
                        class="text-primary"
                        @click="downloadFile(file)"
                        >{{ file.file_name || "" }}</a
                      >
                    </td>
                    <td>{{ file.date || "" }}</td>
                    <td>{{ file.type || "" }}</td>
                    <td>
                      <span v-if="is_downloading && activeFile.id == file.id"
                        ><img
                          width="30px"
                          :src="loading_gif"
                          alt="Loading..."
                        />
                      </span>
                      <button
                        v-else
                        class="btn btn-outline-success btn-sm"
                        @click="downloadFile(file)"
                      >
                        <span class="icon-download mr-0 mr-sm-1"></span>
                        <span class="d-none d-sm-inline">Download</span>
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <p v-else class="p-2 bg-light border-0 rounded">
              No order files. <br />
              Files that you have uploaded will appear here.
            </p>

            <!-- our uploads -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loading_gif from "../../assets/global_assets/images/loading.gif";

import { mapState, mapActions } from "vuex";
import FileUploader from "../formcontrols/FileUploader.vue";
export default {
  name: "OrderFiles",
  components: {
    FileUploader,
  },
  computed: {
    ...mapState("order", ["order", "orderForm"]),
  },
  data() {
    return {
      title: "Upload Order Files",
      is_downloading: false,
      is_uploading: false,
      activeFile: {},
      loading_gif: loading_gif,
    };
  },
  methods: {
    ...mapActions("order", ["_downloadFile", "_uploadFiles"]),
    downloadFile(file) {
      this.activeFile = file;
      this.is_downloading = true;
      this._downloadFile(file.id)
        .then((res) => {
          console.log("res", res);
          const filename = file.file_name;
          if (res.data) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            this.$notify({
              title: "Success",
              text: "File downloaded successifully, check your downloads folder.",
              style: "success",
            });
          } else {
            this.$notify({
              title: "Error",
              text: "Error while downloading.",
              style: "danger",
            });
          }
        })
        .catch((err) => {
          console.log("file upload err", err);
          this.$notify({
            title: "Error",
            text: "Error while downloading file.",
            style: "danger",
          });
        })
        .finally(() => (this.is_downloading = false));
    },

    uploadFiles() {
      this.is_uploading = true;
      this._uploadFiles(this.orderForm)
        .then((res) => {
          // success
          this.$notify({
            title: "Success",
            text: "Files Uploaded",
            style: "success",
          });
          // close modal
          console.log(res);
        })
        .catch((err) => {
          console.log("file upload err", err);
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        })
        .finally(() => {
          this.is_uploading = false;
          this.orderForm.files = []
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table td,
.table th {
  padding: 4px 8px !important;
}
</style>