<template>
  <div>
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <div v-else>
      <!-- <BaseHeader :title="`Order Id #${$route.params.id}`"></BaseHeader> -->

      <!-- <div
        class="page-header page-header-light bg-teal-400 shadow is-animated"
        v-if="deadline && deadline !== false"
      >
        <div class="page-header-content header-elements-md-inline">
          <div class="page-title d-flex py-2">
            <h1>
              <span class="font-weight-semibold text-capitalize">Due In:</span>
              <span>{{deadline || ""}}</span>
            </h1>
            <a href="#" class="header-elements-toggle text-default d-md-none">
              <i class="icon-more"></i>
            </a>
          </div>
        </div>
      </div> -->

      <div class="card is-order-card mt-2 border-0 shadow">
        <div class="card-body p-1 bg-white">
          <div class="row" v-if="Object.keys(order).length > 0">
            <!-- <div class="col-12 text-left" v-if="order.is_paid">
              <div class="media alert alert-success">
                <div class="mr-3">
                  <a
                    href="#"
                    class="
                      btn
                      bg-transparent
                      border-success
                      text-success
                      rounded-round
                      border-2
                      btn-icon
                    "
                  >
                    <i class="icon-checkmark3"></i>
                  </a>
                </div>

                <div class="media-body">
                  Your order is paid!
                  <div class="text-muted">
                    We've received your order payment!
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-12 col-sm-6 col-md-6 text-left" sm="6">
              <h1
                class="font-weight-bold text-capitalize order-title"
                style="font-size: 1.6rem"
              >
                Order ID #{{ order.id || "" }}
              </h1>

              <p>
                <span
                  class="
                    badge
                    bg-primary
                    rounded
                    font-weight-black
                    shadow-sm
                    px-2
                    text-uppercase
                    mr-1
                  "
                  >Status: {{ order.status_text || "" }}
                </span>
                <strong>{{ order.subject_area_text || "" }}</strong> |
                <strong>{{ order.academic_level_text || "" }}</strong> |
                <strong>{{ order.pages || "" }} page(s)</strong>
              </p>
            </div>

            <div class="col-12 col-sm-6 col-md-6" sm="6">
              <div class="m-0 text-left">
                <div class="m-0 text-left" v-if="order.writer">
                  <h4 class="font-weight-bold mb-0 text-left">
                    Writer: {{ order.writer.writer_name || "" }} (
                    {{ order.writer_id || "" }} )
                  </h4>
                  <p class="is-rating-stars-1 d-flex mt-1">
                    <span v-for="(i, index) in 5" :key="index + 'rating'">
                      <span
                        class="text-warning icon mt-1"
                        :class="
                          order.writer.rating.rate >= index + 1
                            ? 'icon-star-full2'
                            : 'icon-star-empty3'
                        "
                      ></span>
                    </span>
                    <span style="font-size: 24px; font-weight: bold"
                      >{{ order.writer.rating.rate || "0" }}
                    </span>
                  </p>
                  <!-- No of reviews -->
                  <h4 class="text-weight-bold d-flex">
                    {{ order.writer.rating.total_reviews || 0 }} Reviews
                  </h4>
                </div>
                <div class="btn-group">
                  <button
                    class="btn btn-outline-primary"
                    data-toggle="modal"
                    data-target="#tipModal"
                  >
                    Tip Writer
                  </button>
                  <router-link
                    :to="{ name: 'EditOrder', params: { id: order.id } }"
                    class="btn btn-outline-primary"
                    variant="info"
                    v-if="!order.is_paid"
                    >Edit</router-link
                  >
                  <router-link
                    :to="{ name: 'payment', params: { id: order.id } }"
                    class="btn btn-primary px-3"
                    variant="info"
                    v-if="!order.is_paid"
                    >Pay</router-link
                  >
                  <button class="btn btn-primary" variant="info" v-else>
                    Paid
                  </button>
                  <button
                    class="btn btn-outline-primary"
                    data-toggle="modal"
                    data-target="#walletModal"
                  >
                    Wallet Topup
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ul class="nav nav-tabs nav-tabs-highlight pl-0 mt-1">
            <li class="nav-item">
              <a
                href="#info"
                class="nav-link active show font-weight-bold text-uppercase"
                data-toggle="tab"
                >Order Info</a
              >
            </li>
            <li class="nav-item">
              <a
                href="#messages"
                class="nav-link font-weight-bold text-uppercase"
                data-toggle="tab"
                >Messages  <span class="badge badge-danger">{{ order.no_messages }}</span>  </a
              >
            </li>
            <li class="nav-item">
              <a
                href="#files"
                class="nav-link font-weight-bold text-uppercase"
                data-toggle="tab"
                >Files <span class="badge badge-danger">{{ order.no_files }}</span> </a
              >
            </li>
            <li v-if="order.writer_id" class="nav-item">
              <a
                href="#rating"
                class="nav-link font-weight-bold text-uppercase"
                data-toggle="tab"
                >Rating</a
              >
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane fade active show" id="info">
              <OrderInfo :order="order"></OrderInfo>
            </div>

            <div class="tab-pane fade" id="messages">
              <OrderMessages :order="order"></OrderMessages>
            </div>

            <div class="tab-pane fade" id="files">
              <OrderFiles></OrderFiles>
            </div>

            <div v-if="order.writer_id" class="tab-pane fade" id="rating">
              <Rating></Rating>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-2 border-0 shadow">
        <div class="card-body text-left">
          <span v-if="order.buttons && order.buttons.length > 0">
            <span v-for="(action, index) in order.buttons" :key="index + 'oa'">
              <router-link
                v-if="action.is_link"
                :to="{ name: action.action, params: { id: order.id } }"
                class="btn m-1"
                :class="`btn-${action.color || 'primary'}`"
                >{{ action.title }}</router-link
              >
              <button
                v-else
                class="btn m-1"
                @click="order_action = action"
                :class="`btn-${action.color || 'primary'}`"
                data-toggle="modal"
                data-target="#exampleModal1"
              >
                {{ action.title }}
              </button>
            </span>
          </span>

          <router-link
            :to="{ name: 'EditOrder', params: { id: order.id } }"
            class="btn m-1 btn-danger"
            variant="info"
            v-if="!order.is_paid"
            >Edit</router-link
          >
          <router-link
            :to="{ name: 'payment', params: { id: order.id } }"
            class="btn m-1 bg-teal"
            variant="info"
            v-if="!order.is_paid"
            >Pay</router-link
          >
          <button
            class="btn m-1 btn-primary"
            data-toggle="modal"
            data-target="#walletModal"
          >
            Wallet Topup
          </button>
        </div>
      </div>

      <UpdateOrderStatus :action="order_action"></UpdateOrderStatus>
    </div>

    <BaseModal :title="'Wallet Topup'" :modalId="'walletModal'">
      <WalletTopup></WalletTopup>
    </BaseModal>

    <BaseModal :title="'Wallet Topup'" :modalId="'tipModal'">
      <TipWriter></TipWriter>
    </BaseModal>
  </div>
</template>

<script>
import WalletTopup from "../../components/payments/modals/WalletTopup.vue";
import TipWriter from "../../components/payments/modals/TipWriter.vue";
import OrderInfo from "../../components/order/OrderInfo.vue";
import OrderFiles from "../../components/order/OrderFiles.vue";
import OrderMessages from "../../components/order/OrderMessages.vue";
import UpdateOrderStatus from "../../components/order/modals/UpdateOrderStatus.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import Rating from "../../components/order/Rating.vue";
export default {
  name: "Order",
  components: {
    OrderInfo,
    OrderFiles,
    OrderMessages,
    Rating,
    UpdateOrderStatus,
    WalletTopup,
    TipWriter,
  },
  computed: {
    ...mapState("order", ["order"]),
  },
  data: () => ({
    order_action: {},
    deadline: false,
    loading: false,
  }),

  methods: {
    ...mapActions("order", ["_getOrder"]),
    ...mapActions("setups", ["getSetups"]),
    ...mapActions("messages", ["_getMessages"]),
    ...mapMutations("order", ["unsetOrder", "changeStatus"]),

    timeout(payload) {
      // console.log(payload);
      var urgency = payload / 1000;
      var d1 = 24 * 60 * 60;
      var d2 = 60 * 60;

      setInterval(() => {
        urgency--;

        var days = Math.floor(urgency / d1),
          hours = Math.floor(urgency / d2) % 24,
          minutes = Math.floor(urgency / 60) % 60,
          seconds = Math.floor(urgency / 1) % 60;

        this.deadline = `${days} Days, ${hours} Hours, ${minutes} Minutes, ${seconds} Seconds`;
      }, 1000);
    },
  },

  async mounted() {
    this.loading = true;
    this.$store.state.loading = true;
    await this._getOrder(this.$route.params.id)
      .then(() => (this.$store.state.loading = false))
      .catch(() => {
        this.$router.push({ name: "404" });
      });
    await this._getMessages(this.$route.params.id);
    await this.getSetups();
    await this.$nextTick();
    this.timeout(this.order.urgency_text);
    this.loading = false;
  },

  beforeDestroy() {
    this.unsetOrder();
  },
};
</script>

<style scoped lang="scss">
.nav-tabs {
  margin-bottom: 0px !important;
}
.order-title {
  max-height: 32px;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .is-order-card {
    min-height: 74vh;
  }
}

.is-animated {
  transition: 0.3s ease-in-out;
}
</style>