<template>
    <div class="text-left">
        <!-- <h4 class="my-2">Order ID: #8989</h4> -->
        <div class="">
            <div class="row">

                <Chat :order="order" class="col-12" />

            </div>
        </div>
    </div>
</template>

<script>
import Chat from "./order-message/Chat.vue";
export default {
    name: "OrderMessages",
    components: {
    Chat,
},
    props: {
        order: {
            type: Object,
            default: () => ({})
        }
    },


}
</script>


